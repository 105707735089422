<template>
    
    <div class="w-full h-full flex flex-col relative overflow-x-hidden">
        <swiper ref="mySwiper" :options="swiperOptions" class="h-full w-full"
            :auto-destroy="true"
            :delete-instance-on-destroy="true"
            :cleanup-styles-on-destroy="true">
            <div class="swiper-pagination" slot="pagination"></div>
            <SlideSL @nextPage="nextPage"/>
            <!-- <SlideSMU /> -->
        </swiper>

    </div>

</template>

<script>

    const SlideSMU = () => import("@/pagesMobile/InterIntra/SMU");
    const SlideSL = () => import("@/pagesMobile/InterIntra/SL");

export default {
    
    components:{
        SlideSMU,
        SlideSL
    },
    data(){
        return{
            swiperOptions: {
                slidesPerView: 'auto',
                spaceBetween: 10,
                centeredSlides: true,
            }
        }
    },
    methods:{
        nextPage(){
            this.$refs.mySwiper.$swiper.slideTo(1, 300)
        }
    }

}
</script>